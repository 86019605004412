import React from "react";

const MiniLink = ({ source, children, childrenTwo }) => {
  return (
    <div className="mini-link">
      <img src={source} alt="icon" />
      <div>
        <h6>{children}</h6>
        <p>{childrenTwo}</p>
      </div>
    </div>
  );
};

export default MiniLink;
