import React from "react";
import "../styles/pages/Comedy.css";
import { representations } from "../Data";
import Section from "../components/Section";
import H2 from "../components/Divers/H2";
import Subtitle from "../components/Divers/Subtitle";
import Paragraph from "../components/Divers/Paragraph";
import Linker from "../components/Buttons/Linker";
import CTA from "../components/Buttons/CTA";
import MiniLink from "../components/Divers/MiniLink";
import H3 from "../components/Divers/H3";
import CardRep from "../components/Divers/CardRep";

import IconProg from "../assets/images/icon-prog.svg";
import IconPin from "../assets/images/icon-pin.svg";
import IconSave from "../assets/images/icon-save.svg";

import Affiche from "../assets/images/dinerdefamille.JPG";
import { motion } from "framer-motion";
import CarouselRep from "../components/Divers/Carousel";
import CarouselSoon from "../components/Divers/CarouselSoon";
import CarouselFest from "../components/Divers/CarouselFest";

const OpacityInAnimationVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const FadeInLeftAnimationVariants = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.05,
    },
  },
};

const FadeInRightAnimationVariants = {
  initial: {
    opacity: 0,
    x: 100,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.05,
    },
  },
};

const Comedy = () => {
  const currentYear = new Date().getFullYear();

  return (
    <main className="page-container">
      <div className="section-rep">
        <H2>Disponible en ce moment !</H2>
        <Subtitle>
          À l'occasion de la Soirée Théâtre à Beaumont-le-Roger, venez nous
          retrouver le 10 novembre 2024 pour notre représentation de Dîner de
          famille. Ne manquez pas cette soirée unique et venez partager ce
          moment avec nous !
        </Subtitle>
        <div className="section-topbanner">
          <motion.div
            className="leftSide"
            variants={FadeInLeftAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
          >
            <H3>“Dîner de famille”</H3>
            <Paragraph>
              Synopsis : A l'occasion de ses 30 ans, Alexandre souhaite demander
              à ses parents d'être les témoins de son mariage.<br></br>
              Son père, animateur de télé parisien, et sa mère, femme au foyer
              provinciale, sont fâchés depuis sa naissance.<br></br>
              Alexandre va utiliser de faux prétextes pour les réunir...
              <br></br>
              Le dîner de famille va-t-il totalement partir en vrille ?
            </Paragraph>
            <div className="content-minilink">
              <MiniLink
                source={IconPin}
                children={"Lieu de la représentation"}
                childrenTwo={
                  "Beaumont le Roger - 27 - Adresse bientôt disponible"
                }
              />
            </div>
            <div className="content-minilink">
              <MiniLink
                source={IconProg}
                children={"Date de la représentation"}
                childrenTwo={
                  "Dimanche 10 Novembre 2024 - Horaires bientôt disponible"
                }
              />
            </div>
            <div className="content-minilink">
              <MiniLink
                source={IconSave}
                children={"Réservation en ligne"}
                childrenTwo={
                  // "Réservez votre billet en ligne pour cette représentation"
                  "Les réservations ne sont pas encore disponibles"
                }
              />
            </div>
            <div className="hr"></div>
            {/* <CTA
              to="#"
              target={"_blank"} 
            >
              Réserver bientôt votre place
            </CTA> */}
          </motion.div>
          <motion.img
            className="rightSide"
            src={Affiche}
            loading="lazy"
            alt="Vu d'une scène de théatre"
            variants={FadeInRightAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
          />
        </div>
        <motion.div
          className="section-carousel"
          variants={OpacityInAnimationVariants}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          <H2>
            Quelques images d'
            <span className="font-bold text-blue">Une Semaine… Pas Plus !</span>
          </H2>
          <Subtitle>
            "Une Semaine… Pas Plus !" fait partie des pièces que nous avons eu le
            plaisir de jouer ! Pour découvrir les coulisses, nos actualités et
            bien plus encore, suivez-nous sur nos réseaux sociaux !
          </Subtitle>
          <CarouselRep />
        </motion.div>
        <motion.div
          className="section-bottombanner"
          variants={OpacityInAnimationVariants}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          <H2>Nos futurs représentation</H2>
          <Subtitle>
            Retrouvez la programmation de nos futures représentations de l'année{" "}
            {currentYear}, incluant les pièces jouées, les lieux et les dates.
          </Subtitle>
          <div className="representations-container">
            <CarouselSoon />
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Comedy;
