import DDF from "./assets/images/dinerdefamille.JPG";

export const representations = [
  // {
  //   date: "10 Novembre 2024",
  //   location: "Beaumont le Roger - 27",
  //   eventName: "Soirée Théâtre",
  //   piecePlayed: "Dîner de famille",
  //   image: DDF,
  // },
  {
    date: "23 Novembre 2024",
    location: "Médan - 78",
    eventName: "Soirée Théâtre",
    piecePlayed: "Dîner de famille",
    image: DDF,
  },
  {
    date: "24 Novembre 2024",
    location: "Port Mort - 27",
    eventName: "Festival",
    piecePlayed: "Dîner de famille",
    image: DDF,
  },
  {
    date: "14 ou 15 Décembre 2024",
    location: "Ablis - 78",
    eventName: "Festival",
    piecePlayed: "Dîner de famille",
    image: DDF,
  },
];
