import React from "react";
import Section from "../components/Section.jsx";
import H2 from "../components/Divers/H2.jsx";
import Subtitle from "../components/Divers/Subtitle.jsx";
import Paragraph from "../components/Divers/Paragraph.jsx";

const Cookies = () => {
  return (
    <div className="wrapper-ml">
      {" "}
      <Section
        children={
          <>
            <H2>Politique de Confidentialité</H2>
            <Subtitle>
              Bienvenue sur le site de La Divine Comédie. La protection de vos
              données personnelles est une priorité pour nous. Cette politique
              de confidentialité décrit comment nous collectons, utilisons et
              protégeons vos informations personnelles lorsque vous utilisez
              notre formulaire de contact.
            </Subtitle>
          </>
        }
        childrenTwo={""}
        childrenThree={""}
      />
      <Paragraph>
        <br></br>
        <br></br>
        1. Collecte des données personnelles
        <br></br>
        <br></br>
        Lorsque vous utilisez notre formulaire de contact, nous collectons les
        informations personnelles suivantes : Nom Prénom Adresse e-mail Ces
        informations sont fournies volontairement par vous lorsque vous
        remplissez et soumettez le formulaire de contact.
        <br></br>
        <br></br>
        2. Utilisation des données personnelles
        <br></br>
        <br></br>
        Les informations personnelles que nous collectons sont utilisées
        uniquement dans les buts suivants : Répondre à vos demandes et questions
        envoyées via le formulaire de contact. Vous fournir des informations ou
        des services que vous avez demandés. Nous ne vendons, n'échangeons, ni
        ne transférons vos informations personnelles à des tiers sans votre
        consentement explicite, sauf si cela est requis par la loi.
        <br></br>
        <br></br>
        3. Protection des données personnelles
        <br></br>
        <br></br>
        Nous mettons en œuvre des mesures de sécurité appropriées pour protéger
        vos informations personnelles contre tout accès, utilisation ou
        divulgation non autorisés. Nous utilisons des technologies et des
        procédures de sécurité reconnues pour garantir la protection de vos
        données.
        <br></br>
        <br></br>
        4. Partage des données personnelles
        <br></br>
        <br></br>
        Nous ne partageons pas vos informations personnelles avec des tiers,
        sauf dans les circonstances suivantes : Lorsque nous avons votre
        consentement explicite pour le faire. Lorsque la loi nous y oblige ou
        pour protéger nos droits légaux.
        <br></br>
        <br></br>
        5. Durée de conservation des données personnelles
        <br></br>
        <br></br>
        Nous conservons vos informations personnelles pendant une durée
        indéfinie, ou jusqu'à ce que vous nous demandiez de les supprimer. Vous
        pouvez demander la suppression de vos données à tout moment en nous
        contactant à l'adresse e-mail fournie ci-dessous.
        <br></br>
        <br></br>
        6. Vos droits
        <br></br>
        <br></br>
        Conformément à la réglementation en vigueur, vous disposez des droits
        suivants concernant vos données personnelles : Droit d'accès : Vous
        pouvez demander à consulter les informations personnelles que nous
        détenons à votre sujet. Droit de rectification : Vous pouvez demander la
        correction de toute information personnelle inexacte ou incomplète.
        Droit à l'effacement : Vous pouvez demander la suppression de vos
        informations personnelles. Droit à la limitation du traitement : Vous
        pouvez demander la limitation du traitement de vos informations
        personnelles. Droit à la portabilité : Vous pouvez demander à recevoir
        vos informations personnelles dans un format structuré, couramment
        utilisé et lisible par machine. Pour exercer ces droits, veuillez nous
        contacter à l'adresse e-mail suivante : ladivinecomedie78@gmail.com.
        <br></br>
        <br></br>
        7. Modifications de la politique de confidentialité
        <br></br>
        <br></br>
        Nous nous réservons le droit de modifier cette politique de
        confidentialité à tout moment. Toute modification sera publiée sur cette
        page avec une date de mise à jour. Nous vous encourageons à consulter
        régulièrement cette page pour prendre connaissance des éventuelles
        modifications.
        <br></br>
        <br></br>
        8. Contact
        <br></br>
        <br></br>
        Si vous avez des questions concernant cette politique de confidentialité
        ou la manière dont nous traitons vos données personnelles, vous pouvez
        nous contacter à l'adresse e-mail suivante :
        ladivinecomedie78@gmail.com.
      </Paragraph>
    </div>
  );
};

export default Cookies;
