import React from "react";
import Section from "../components/Section.jsx";
import H2 from "../components/Divers/H2.jsx";
import Subtitle from "../components/Divers/Subtitle.jsx";
import Paragraph from "../components/Divers/Paragraph.jsx";

const PersoData = () => {
  return (
    <div className="wrapper-ml">
      <Section
        children={
          <>
            <H2>Conditions Générales d'Utilisation (CGU) </H2>
            <Subtitle>
              Bienvenue sur le site ladivinecomedie.fr/.com/.org. En accédant et
              en utilisant ce site, vous acceptez les présentes conditions
              générales d'utilisation. Veuillez les lire attentivement.
            </Subtitle>
          </>
        }
        childrenTwo={""}
        childrenThree={""}
      />
      <Paragraph>
        1. Acceptation des conditions d'utilisation
        <br></br>
        <br></br>
        L'accès au site ladivinecomedie78.fr/.com/.net et son utilisation sont
        soumis aux présentes conditions générales d'utilisation, ainsi qu'aux
        lois et/ou règlements applicables. En utilisant ce site, vous
        reconnaissez avoir lu, compris et accepté sans réserve ces conditions.
        <br></br>
        <br></br>
        2. Description des services fournis
        <br></br>
        <br></br>
        Le site ladivinecomedie78.fr/.com/.net a pour objet de fournir une
        information concernant l’ensemble des activités de la société. La Divine
        Comédie s’efforce de fournir sur le site ladivinecomedie78.fr/.com/.net
        des informations aussi précises que possible. Toutefois, il ne pourra
        être tenu responsable des omissions, des inexactitudes et des carences
        dans la mise à jour, qu’elles soient de son fait ou du fait des tiers
        partenaires qui lui fournissent ces informations.
        <br></br>
        <br></br>
        3. Propriété intellectuelle et contrefaçons
        <br></br>
        <br></br>
        La Divine Comédie est propriétaire des droits de propriété
        intellectuelle ou détient les droits d’usage sur tous les éléments
        accessibles sur le site, notamment les textes, images, graphismes, logo,
        icônes, sons, logiciels, etc. Toute reproduction, représentation,
        modification, publication, adaptation de tout ou partie des éléments du
        site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf
        autorisation écrite préalable de : La Divine Comédie. Toute exploitation
        non autorisée du site ou de l’un quelconque des éléments qu’il contient
        sera considérée comme constitutive d’une contrefaçon et poursuivie
        conformément aux dispositions des articles L.335-2 et suivants du Code
        de Propriété Intellectuelle.
        <br></br>
        <br></br>
        4. Limitations de responsabilité
        <br></br>
        <br></br>
        La Divine Comédie ne pourra être tenu responsable des dommages directs
        et indirects causés au matériel de l’utilisateur, lors de l’accès au
        site ladivinecomedie78.fr/.com/.net, et résultant soit de l’utilisation
        d’un matériel ne répondant pas aux spécifications indiquées au point 5,
        soit de l’apparition d’un bug ou d’une incompatibilité. La Divine
        Comédie ne pourra également être tenu responsable des dommages indirects
        (tels par exemple qu’une perte de marché ou perte d’une chance)
        consécutifs à l’utilisation du site ladivinecomedie78.fr/.com/.net. Des
        espaces interactifs (possibilité de poser des questions dans l’espace
        contact) sont à la disposition des utilisateurs. La Divine Comédie se
        réserve le droit de supprimer, sans mise en demeure préalable, tout
        contenu déposé dans cet espace qui contreviendrait à la législation
        applicable en France, en particulier aux dispositions relatives à la
        protection des données.
        <br></br>
        <br></br>
        5. Gestion des données personnelles
        <br></br>
        <br></br>
        En France, les données personnelles sont notamment protégées par la loi
        n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article
        L. 226-13 du Code pénal et le Règlement Général sur la Protection des
        Données (RGPD) du 27 avril 2016. À l’occasion de l’utilisation du site
        ladivinecomedie78.fr/.com/.net, peuvent être recueillies : l’URL des
        liens par l’intermédiaire desquels l’utilisateur a accédé au site
        ladivinecomedie78.fr/.com/.net, le fournisseur d’accès de l’utilisateur,
        l’adresse de protocole Internet (IP) de l’utilisateur. En tout état de
        cause La Divine Comédie ne collecte des informations personnelles
        relatives à l’utilisateur que pour le besoin de certains services
        proposés par le site ladivinecomedie78.fr/.com/.net. L’utilisateur
        fournit ces informations en toute connaissance de cause, notamment
        lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à
        l’utilisateur du site ladivinecomedie78.fr/.com/.net l’obligation ou non
        de fournir ces informations. Conformément aux dispositions des articles
        38 et suivants de la loi 78-17 du 6 janvier 1978 relative à
        l’informatique, aux fichiers et aux libertés, tout utilisateur dispose
        d’un droit d’accès, de rectification et d’opposition aux données
        personnelles le concernant, en effectuant sa demande écrite et signée,
        accompagnée d’une copie du titre d’identité avec signature du titulaire
        de la pièce, en précisant l’adresse à laquelle la réponse doit être
        envoyée. Aucune information personnelle de l’utilisateur du site
        ladivinecomedie78.fr/.com/.net n’est publiée à l’insu de l’utilisateur,
        échangée, transférée, cédée ou vendue sur un support quelconque à des
        tiers. Seule l’hypothèse du rachat de La Divine Comédie et de ses droits
        permettrait la transmission des dites informations à l’éventuel
        acquéreur qui serait à son tour tenu de la même obligation de
        conservation et de modification des données vis-à-vis de l’utilisateur
        du site ladivinecomedie78.fr/.com/.net. Les bases de données sont
        protégées par les dispositions de la loi du 1er juillet 1998 transposant
        la directive 96/9 du 11 mars 1996 relative à la protection juridique des
        bases de données.
        <br></br>
        <br></br>
        6. Liens hypertextes et cookies
        <br></br>
        <br></br>
        Le site ladivinecomedie78.fr/.com/.net contient un certain nombre de
        liens hypertextes vers d’autres sites, mis en place avec l’autorisation
        de La Divine Comédie. Cependant, La Divine Comédie n’a pas la
        possibilité de vérifier le contenu des sites ainsi visités, et
        n’assumera en conséquence aucune responsabilité de ce fait. La
        navigation sur le site ladivinecomedie78.fr/.com/.net est susceptible de
        provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
        Un cookie est un fichier de petite taille, qui ne permet pas
        l’identification de l’utilisateur, mais qui enregistre des informations
        relatives à la navigation d’un ordinateur sur un site. Les données ainsi
        obtenues visent à faciliter la navigation ultérieure sur le site, et ont
        également vocation à permettre diverses mesures de fréquentation. Le
        refus d’installation d’un cookie peut entraîner l’impossibilité
        d’accéder à certains services. L’utilisateur peut toutefois configurer
        son ordinateur de la manière suivante, pour refuser l’installation des
        cookies : Sous Internet Explorer : onglet outil (pictogramme en forme de
        rouage en haut a droite) / options internet. Cliquez sur Confidentialité
        et choisissez Bloquer tous les cookies. Validez sur Ok. Sous Firefox :
        en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis
        aller dans l’onglet Options. Cliquer sur l’onglet Vie privée. Paramétrez
        les Règles de conservation sur : utiliser les paramètres personnalisés
        pour l’historique. Enfin décochez-la pour désactiver les cookies. Sous
        Safari : Cliquez en haut à droite du navigateur sur le pictogramme de
        menu (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur
        Afficher les paramètres avancés. Dans la section "Confidentialité",
        cliquez sur Paramètres de contenu. Dans la section "Cookies", vous
        pouvez bloquer les cookies. Sous Chrome : Cliquez en haut à droite du
        navigateur sur le pictogramme de menu (symbolisé par trois lignes
        horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les
        paramètres avancés. Dans la section "Confidentialité", cliquez sur
        préférences. Dans l’onglet "Confidentialité", vous pouvez bloquer les
        cookies.
        <br></br>
        <br></br>
        7. Droit applicable et attribution de juridiction
        <br></br>
        <br></br>
        Tout litige en relation avec l’utilisation du site
        ladivinecomedie78.fr/.com/.net est soumis au droit français. Il est fait
        attribution exclusive de juridiction aux tribunaux compétents de Paris.
        <br></br>
        <br></br>
        8. Les principales lois concernées
        <br></br>
        <br></br>
        Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n°
        2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux
        libertés. Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
        l'économie numérique.
        <br></br>
        <br></br>
        9. Lexique Utilisateur
        <br></br>
        <br></br>
        Internaute se connectant, utilisant le site susnommé. Informations
        personnelles : « les informations qui permettent, sous quelque forme que
        ce soit, directement ou non, l'identification des personnes physiques
        auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6
        janvier 1978).
      </Paragraph>
    </div>
  );
};

export default PersoData;
